import { useGlobalPageState } from '@kaliber/use-global-page-state'
import { Button } from '/features/buildingBlocks/Button'
import { useTranslate } from '/machinery/I18n'

import styles from './ApplyButton.css'

export function ApplyButton({ layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const [hasApplied = false] = useGlobalPageState('has-applied-state')

  return (
    <div className={cx(styles.component, layoutClassName, hasApplied && styles.isDisabled)}>
      <Button href='#form' dataX='scroll-to-form' layoutClassName={styles.buttonLayout} disabled={hasApplied}>
        {__`job-detail-apply-now`}
      </Button>
    </div>
  )
}
