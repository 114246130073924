import { useGlobalPageState } from '@kaliber/use-global-page-state'
import { useScrollActivity } from '/machinery/useScrollActivity'
import { useScrollDepth } from '/machinery/useScrollDepth'
import { useGetHashFromUrl } from '/machinery/useGetHashFromUrl'
import { ApplyButton } from './ApplyButton'
import cssStyleContext from '/cssGlobal/style-context.css'

import styles from './MobileApplyButton.css'

export function MobileApplyButton({ layoutClassName = undefined }) {
  const { direction } = useScrollActivity({ delta: 100 })
  const isAboveFold = useScrollDepth({ offset: -10 })
  const hash = useGetHashFromUrl()
  const [hasApplied = false] = useGlobalPageState('has-applied-state')

  const isHidden = React.useMemo(() => {
    if (!hash) return false
    return hash === 'form' || !isAboveFold || direction === 'up' || hasApplied
  }, [hash, isAboveFold, direction, hasApplied])

  return (
    <div
      className={cx(
        styles.component,
        isHidden && styles.isHidden,
        layoutClassName
      )}
      data-style-context={cssStyleContext.contextBlack}
    >
      <div className={styles.buttonContainer}>
        <ApplyButton />
      </div>
    </div>
  )
}

